import React from 'react'
import { useSnapshot } from 'valtio'
import {
  ProfileSections,
  ProfileStoreContext,
  useProfileStore,
} from './ProfileStore.ts'
import { useLazyRef } from '../../shared/lib/hooks.ts'
import { useAppModule } from '../appContext.ts'
import { ProfileManageAccount } from './ProfileManageAccount.tsx'
import { ProfileOnboardingInfo } from './ProfileOnboardingInfo.tsx'
import { ProfileSubscription } from './ProfileSubscription.tsx'
import { ProfileSupport } from './ProfileSupport.tsx'
import { ProfileNotifications } from './ProfileNotifications.tsx'
import { ProfileAppLanguage } from './ProfileAppLanguage.tsx'
import { ProfileAbout } from './ProfileAbout.tsx'
import { emptyFn, getValueByKey } from '../../shared/lib/utils.ts'
import { FormattedMessage } from 'react-intl'
import { planInfos } from '../onboarding/subscriptionView.tsx'
import { OnboardingData } from '../onboarding/onboardingData.ts'
import {
  ProfileButton,
  ProfileDrawer,
  ProfileMenuBlock,
  ProfileTitle,
} from './ProfileComponents.tsx'
import { Button } from '../../shared/ui/button/button.tsx'

export const ProfileView = (props: { onboardingData: OnboardingData }) => {
  const appModule = useAppModule()
  const store = useLazyRef(() =>
    appModule.profileStore(props.onboardingData),
  ).current
  const state = useSnapshot(store.state)
  const componentMap = {
    manageAccount: <ProfileManageAccount />,
    changeOnboardingInfo: <ProfileOnboardingInfo />,
    manageSubscription: <ProfileSubscription />,
    support: <ProfileSupport />,
    notifications: <ProfileNotifications />,
    appLanguage: <ProfileAppLanguage />,
    about: <ProfileAbout />,
  }

  const content = getValueByKey(componentMap, state.currentSection) ?? (
    <ProfileMainMenu />
  )

  return (
    <ProfileStoreContext.Provider value={store}>
      <div className="mx-auto w-full max-w-800 p-16">{content}</div>
    </ProfileStoreContext.Provider>
  )
}
function getMenuImage(image: ProfileSections | 'logout') {
  return `/images/profile/${image}.svg`
}
function ProfileMainMenu() {
  const store = useProfileStore()
  const state = useSnapshot(store.state)
  const appStore = useAppModule().appStore
  const appState = useSnapshot(appStore.state)
  return (
    <div className="mb-100 flex flex-col">
      <ProfileTitle
        hideBack
        title={<FormattedMessage id="user.personalProfile" />}
      />
      <div className="flex flex-col gap-16">
        <ProfileMenuBlock>
          <ProfileButton
            imageUrl={getMenuImage('manageAccount')}
            onClick={() => {
              store.setSection('manageAccount')
            }}
            title={<FormattedMessage id="user.manageAccount" />}
            subtitle={state.email}
          />
          <ProfileButton
            imageUrl={getMenuImage('changeOnboardingInfo')}
            onClick={() => {
              store.setSection('changeOnboardingInfo')
            }}
            title={<FormattedMessage id="user.title.changeOnboardingInfo" />}
          />
          <ProfileButton
            imageUrl={getMenuImage('manageSubscription')}
            onClick={() => {
              store.setSection('manageSubscription')
            }}
            title={<FormattedMessage id="user.manageSubscription" />}
            subtitle={
              state.subscription && (
                <FormattedMessage
                  id="months"
                  values={{ count: planInfos[state.subscription.plan].month }}
                />
              )
            }
          />
        </ProfileMenuBlock>

        <ProfileMenuBlock>
          <ProfileButton
            imageUrl={getMenuImage('faq')}
            onClick={() =>
              window.open('https://img.edman.ai/pdf/edman_faq.pdf')
            }
            title="FAQ"
          />
          <ProfileButton
            imageUrl={getMenuImage('support')}
            onClick={() => {
              store.setSection('support')
            }}
            title={<FormattedMessage id="user.support" />}
          />
          <ProfileButton
            imageUrl={getMenuImage('notifications')}
            onClick={emptyFn}
            title={<FormattedMessage id="user.notifications" />}
          />
          <ProfileButton
            imageUrl={getMenuImage('appLanguage')}
            onClick={() => {
              store.setSection('appLanguage')
            }}
            title={<FormattedMessage id="user.title.appLang" />}
            subtitle={appState.language == 'en' ? 'English' : 'Русский'}
          />
          <ProfileButton
            imageUrl={getMenuImage('about')}
            onClick={() => {
              store.setSection('about')
            }}
            title={<FormattedMessage id="user.aboutUs" />}
          />
        </ProfileMenuBlock>

        <ProfileMenuBlock>
          <ProfileButton
            imageUrl={getMenuImage('logout')}
            onClick={() => {
              store.setShowLogout(true)
            }}
            title={<FormattedMessage id="Log out" />}
          />
        </ProfileMenuBlock>
      </div>
      <LogoutDrawer />
    </div>
  )
}

function LogoutDrawer() {
  const store = useProfileStore()
  const authStore = useAppModule().authStore
  const state = useSnapshot(store.state)
  return (
    <ProfileDrawer
      title={<FormattedMessage id="Log out?" />}
      open={state.showLogout}
    >
      <div className="flex flex-col gap-16">
        <div>
          <FormattedMessage id="areYouSureLogout" />
        </div>

        <Button
          rounded="full"
          size="large"
          bg="red-gradient"
          onClick={() => {
            authStore.logoutAndRefresh()
          }}
        >
          <FormattedMessage id="Log out" />
        </Button>

        <Button
          bg="transparent"
          size="large"
          onClick={() => {
            store.closeDrawer()
          }}
        >
          <FormattedMessage id="cancel" />
        </Button>
      </div>
    </ProfileDrawer>
  )
}
